import React from 'react';
import './landingpage.css';

const LandingPage = () =>{
    return(
        <>
            <div className = 'container' id = 'Home'>
                <h1>Ryan Lee</h1>
                <hr/>
                <h2>An Interactive, Personal Portfolio</h2>
            </div>
        </>
    );
};


export default LandingPage;